.body {
    width: 100%;
    height: 400vh;
    color: white;
    scrollbar-width: none;
}

a {
    text-decoration: none;
    color: white;
}

body::-webkit-scrollbar {
    display: none;
}

body {
    /* Smooth scrolling */
    scroll-behavior: smooth;
    background-color: black;
    cursor: none;
}