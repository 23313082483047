/* Poppins */

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

* {
    font-family: 'Poppins', sans-serif;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* Gradient background */
    background: linear-gradient(45deg, rgb(0, 93, 116) 0%, rgb(113, 252, 131) 100%);
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 1.5rem;
    text-align: center;
    scroll-behavior: none;
}

.overlay h1 {
    font-size: 2rem;
    font-weight: 500;
}

.overlay h2 {
    font-size: 1rem;
    font-weight: 400;
}