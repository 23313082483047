.brand-row {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;
    gap: 100px;
    animation: scroll 50s linear infinite;
}

/* brand row continuous scrolling to the right */
@keyframes scroll {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(100%);
    }
}

/* filepath: /Users/accelbia/Desktop/Projects/homesite/src/assets/pages/home/mainbody/MainBody.css */
.mainbody {
    margin-top: 100vh;
    margin-bottom: 100px;
    min-height: calc(100vh);
    color: white;
    font-family: 'Poppins', sans-serif;
    font-size: 1.5rem;
    font-weight: 400;
    margin-left: 10%;
}

h3 {
    font-size: 2rem;
    font-weight: 400;
    margin-bottom: 50px;
}