@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap');

* {
    font-family: 'Poppins', sans-serif;
    font-weight: 300;
}

@keyframes appear_on_load {
    0% {
        opacity: 0;
        transform: translateY(-1rem);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.navbar {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 10;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 1rem;
    padding-bottom: 1rem;
    font-weight: 200;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    animation: appear_on_load 3s ease;
    transition: all 0.3s ease;
}

.navbar-right {
    display: flex;
    gap: 1rem;
    margin-right: 2rem;
}

@keyframes appear_after_navload {
    0% {
        opacity: 0;
        transform: translateX(-1rem);
    }

    50% {
        opacity: 0;
        transform: translateX(-1rem);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.navbar button {
    padding: 0.5rem 1rem;
    border: transparent 1px solid;
    background-color: transparent;
    color: rgba(148, 148, 148, 1);
    transition: all 0.3s ease;
    font-weight: 400;
    animation: appear_after_navload 2s ease;
}

.navbar button:hover {
    color: white;
    border: white 1px solid;
    box-shadow: #3cb6ab 0 0 10px;
}

.navbar button:active {
    border: white 1px solid;
    background-color: white;
    color: black;
}